<template>
  <!--footer block-->
  <footer id="main-footer" class="footer">
    <nav class="container">
      <div class="accordion row">
        <div class="col-xl-4 col-md-4">
          <header>
            <button
              class="footer-item-title footer-collapse text-uppercase bg-transparent shadow-none p-0 border-0 w-100 text-start position-relative event-pointer-web-none"
              :class="{'collapsed': !isShowOne}"
              type="button"
              @click="isShowOne = !isShowOne"
            >
              {{ $t('footer.gold_center.title') }}
            </button>
          </header>
          <app-transition-expat>
            <div v-show="isShowOne" class="d-md-block accordion-collapse collapse show">
              <ul class="list-unstyled mb-md-0 footer-accordeon">
                <li class="footer-item">
                  <nuxt-link
                    :to="{ path: localePath(`/about-gold-center`) }"
                    tag="a"
                    :aria-label="$t('footer.gold_center.about_us')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('footer.gold_center.about_us') }}
                  </nuxt-link>
                </li>
                <li class="footer-item">
                  <nuxt-link
                    :to="{ path: localePath(`/FAQ`) }"
                    tag="a"
                    :aria-label="$t('footer.gold_center.faq')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('footer.gold_center.faq') }}
                  </nuxt-link>
                </li>
                <li class="footer-item">
                  <nuxt-link
                    :to="{ path: localePath(`/guaranteed-checking`) }"
                    tag="a"
                    :aria-label="$t('menu.guaranteed_checking')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('menu.guaranteed_checking') }}
                  </nuxt-link>
                </li>
                <li class="footer-item">
                  <nuxt-link
                    :to="{ path: localePath(`/seller`) }"
                    tag="a"
                    :aria-label="$t('footer.gold_center.shop_stores')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('footer.gold_center.shop_stores') }}
                  </nuxt-link>
                </li>

                <li class="footer-item">
                  <nuxt-link
                    :to="{ path: localePath(`/souvenir`) }"
                    tag="a"
                    :aria-label="$t('footer.gold_center.souvenir')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('footer.gold_center.souvenir') }}
                  </nuxt-link>
                </li>

                <li class="footer-item">
                  <nuxt-link
                    :to="{ path: localePath(`/jobs`) }"
                    tag="a"
                    :aria-label="$t('basic.job')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('basic.job') }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </app-transition-expat>
        </div>
        <div class="col-xl-4 col-md-4">
          <header class="accordion-header">
            <button
              class="footer-item-title footer-collapse text-uppercase bg-transparent shadow-none p-0 border-0 w-100 text-start position-relative event-pointer-web-none"
              :class="{'collapsed': !isShowTwo}"
              type="button"
              @click="isShowTwo = !isShowTwo"
            >
              {{ $t('footer.help.title') }}
            </button>
          </header>
          <app-transition-expat>
            <div v-show="isShowTwo" class="d-md-block accordion-collapse collapse show">
              <ul class="list-unstyled mb-md-0 footer-accordeon">
                <li class="footer-item">
                  <nuxt-link
                    :class="{ disabled: !currentUser }"
                    :to="{ path: localePath(`/buyer-account/settings`) }"
                    tag="a"
                    :aria-label="$t('footer.help.account')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('footer.help.account') }}
                  </nuxt-link>
                </li>
                <li class="footer-item">
                  <nuxt-link
                    :to="{ path: localePath(`/buyer-account/order-history`) }"
                    tag="a"
                    :class="{ disabled: !currentUser }"
                    :aria-label="$t('footer.help.orders')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('footer.help.orders') }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </app-transition-expat>
        </div>
        <div class="col-xl-4 col-md-4">
          <header class="accordion-header">
            <button
              class="event-pointer-web-none footer-collapse footer-item-title text-uppercase bg-transparent shadow-none p-0 border-0 w-100 text-start position-relative"
              :class="{'collapsed': !isShowThree}"
              type="button"
              @click="isShowThree = !isShowThree"
            >
              {{ $t('footer.contact.title') }}
            </button>
          </header>
          <app-transition-expat>
            <div v-show="isShowThree" class="d-md-block accordion-collapse collapse show">
              <ul class="list-unstyled mb-md-0 footer-accordeon">
                <li class="footer-item">
                  <address class="footer-item__link text-decoration-none">
                    <!-- Main office, 21 Pushkin st, Yerevan -->
                    {{ $t('footer.contact.address') }}
                  </address>
                </li>
                <li class="footer-item">
                  <a
                    :href="$t('footer.contact.phone.value')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('footer.contact.phone.key') }}
                  </a>
                </li>
                <li class="footer-item">
                  <a
                    :href="$t('footer.contact.email.value')"
                    class="footer-item__link text-decoration-none"
                  >
                    {{ $t('footer.contact.email.key') }}
                  </a>
                </li>
              </ul>
              <div class="d-md-block mt-3">
                <header class="accordion-header text-uppercase">
                  {{ $t('footer.payment_methods') }}
                </header>
                <ul class="mb-0 list-unstyled d-flex payments-wrapper">
                  <li class="payment-icon">
                    <img src="~/assets/images/payment-visa.svg" alt="ArCa" width="40" height="25" data-not-lazy />
                  </li>
                  <li class="payment-icon">
                    <img src="~/assets/images/payment-mastercard.svg" alt="ArCa" width="40" height="25" data-not-lazy />
                  </li>
                  <li class="payment-icon">
                    <img src="~/assets/images/payment-arca.svg" alt="ArCa" width="40" height="25" data-not-lazy />
                  </li>
                  <li class="payment-icon">
                    <img src="~/assets/images/idram.svg" alt="ArCa" width="40" height="25" data-not-lazy />
                  </li>
                  <li class="payment-icon">
                    <img src="~/assets/images/telcell.svg" alt="Telcell" width="40" height="25" data-not-lazy>
                  </li>
                </ul>
              </div>
            </div>
          </app-transition-expat>
        </div>
      </div>
    </nav>
    <div class="secondary-footer">
      <div class="container">
        <client-only>
          <EmailForm
            v-if="isMobile"
            endpoint="products"
            :isThankYou="true"
            label="alert.notifi_product.subscribeTxt"
            btnTxt="alert.notifi_product.subscribe"
          />
        </client-only>
        <h2 class="footer-item-title text-uppercase w-100 text-center">
          {{ $t('footer.social_links') }}
        </h2>
        <ul class="list-unstyled d-flex justify-content-center icons-container">
          <li class="icons-container__itam">
            <a
              href="https://www.instagram.com/goldcenter.am/"
              target="_blank"
              rel="noreferrer"
              aria-label="Incon"
              class="text-decoration-none"
            >
              <img src="~/assets/images/soc-insta.svg" :alt="`instagram`" width="20" height="20" data-not-lazy />
            </a>
          </li>
          <li class="icons-container__itam">
            <a
              href="https://www.facebook.com/GoldCenterArmenia/"
              target="_blank"
              rel="noreferrer"
              aria-label="Incon"
              class="text-decoration-none"
            >
              <img src="~/assets/images/soc-fb.svg" :alt="`facebook`"  width="11" height="20" data-not-lazy />
            </a>
          </li>
          <li class="icons-container__itam">
            <a
              href="https://t.me/armgoldcenter"
              target="_blank"
              rel="noreferrer"
              aria-label="Incon"
              class="text-decoration-none"
            >
              <img src="~/assets/images/soc-tg.svg" :alt="`instagram`"  width="22" height="18" data-not-lazy />
            </a>
          </li>
          <li class="icons-container__itam">
            <a
              aria-label="Incon"
              href="https://www.tiktok.com/@goldcenter.am"
              target="_blank"
              rel="noreferrer"
              class="text-decoration-none"
            >
              <img src="~/assets/images/soc-tiktok.svg" :alt="`tiktok`"  width="19" height="19" data-not-lazy />
            </a>
          </li>
          <li class="icons-container__itam">
            <a
              aria-label="Incon"
              href="https://www.youtube.com/@GoldCenter-am"
              target="_blank"
              rel="noreferrer"
              class="text-decoration-none"
            >
              <img src="~/assets/images/soc-youtube.svg" :alt="`youtube`"  width="22" height="22" data-not-lazy />
            </a>
          </li>
        </ul>
        <ul
          class="list-unstyled d-flex flex-column flex-md-row justify-content-md-center privacy-footer mb-0"
        >
          <a
            style="display: block; width: 100px; float: right"
            href="https://www.liveinternet.ru/click"
            target="_blank"
            rel="noreferrer"
            hidden
          ><img
            id="licntF3B2"
            width="88"
            height="15"
            style="border: 0"
            title="LiveInternet: number of visitors for today is shown"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAIBTAA7"
            alt=""
          ></a>
          <li class="privacy-footer__item">
            ©{{ new Date().getFullYear() }} Gold Center
          </li>
          <li class="privacy-footer__item">
            <nuxt-link
              tag="a"
              :to="{
                path: localePath(`/terms-of-conditions`)
              }"
              class="privacy-footer__item__link text-decoration-none"
            >
              {{ $t('basic.terms_of_use') }}
            </nuxt-link>
          </li>
          <li class="privacy-footer__item">
            <nuxt-link
              tag="a"
              :to="{
                path: localePath(`/privacy-policy`)
              }"
              class="privacy-footer__item__link text-decoration-none"
            >
              {{ $t('basic.privacy_policy') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'AppFooter',

  components: {
    EmailForm: () => import('~/components/EmailForm.vue')
  },
  data () {
    return {
      isShowOne: true,
      isShowTwo: false,
      isShowThree: false
    }
  }

  // async mounted () {
  //   if (process.browser) {
  //     ;(function (d, s) {
  //       d.getElementById('licntF3B2').src =
  //         'https://counter.yadro.ru/hit?t26.6;r' +
  //         escape(d.referrer) +
  //         (typeof s === 'undefined'
  //           ? ''
  //           : ';s' +
  //             s.width +
  //             '*' +
  //             s.height +
  //             '*' +
  //             (s.colorDepth ? s.colorDepth : s.pixelDepth)) +
  //         ';u' +
  //         escape(d.URL) +
  //         ';h' +
  //         escape(d.title.substring(0, 150)) +
  //         ';' +
  //         Math.random()
  //     })(document, screen)
  //   }
  // }
}
</script>

<style scoped>
.footer {
  padding: 60px 0 30px;
  margin-top: 30px;
}

.footer-item:not(:last-child) {
  margin-bottom: 16px;
}

.footer-item__link,
.footer-item__link:hover,
.footer-item__link:focus,
.footer-item__link:visited,
.footer-item__link:active {
  color: var(--primary-color-dark);
  line-height: 1;
}

.footer-item-title {
  color: #000;
  margin-bottom: 39px;
  font-weight: 400;
  font-size: 20px;
}

.subscribe-form {
  border-bottom: 1px solid var(--primary-color-dark);
}

.subscribe-form__label {
  color: #949494;
}

.subscribe-text {
  color: #010101;
  margin: 24px 0 0;
  max-width: 240px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-1rem) translateX(0rem);
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1rem;
}

.footer-payment-block {
  margin-top: 20px;
}

.payment-icon:not(:last-child) {
  margin-right: 20px;
}

.secondary-footer {
  border-top: 1px solid var(--seccondary-color-transparetn);
  margin-top: 60px;
  padding-top: 40px;
}

.icons-container__itam:not(:last-child) {
  margin-right: 37px;
}

.icons-container {
  margin-bottom: 42px;
}

.privacy-footer__item:not(:last-child) {
  margin-right: 90px;
}

.privacy-footer__item,
.privacy-footer__item__link,
.privacy-footer__item__link:hover,
.privacy-footer__item__link:active,
.privacy-footer__item__link:visited {
  color: rgba(0, 0, 0, 0.8);
}

@media all and (max-width: 1199.9px) {
  .footer-payment-block,
  .sign-up-container {
    margin-top: 41px;
  }

  .subscribe-text {
    max-width: 100%;
  }

  .footer {
    padding: 30px 0 40px;
    margin-top: 50px;
  }

  .secondary-footer {
    margin-top: 41px;
    padding-top: 32px;
  }
}

@media all and (max-width: 766.9px) {
  .footer {
    padding: 20px 0 15px;
    margin-top: 65px;
  }
  .accordion .footer-collapse {
    padding-right: 20px !important;
  }
  .accordion .footer-collapse:not(.collapsed)::after {
    transform: rotate(45deg);
  }
  .accordion .footer-collapse::after {
    content: "";
    display: block;
    background: url(/images/plus.svg) no-repeat center;
    background-size: contain;
    width: 14px;
    height: 21px;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 300ms;
  }

  .footer-payment-block,
  .sign-up-container {
    margin-top: 0;
  }

  .footer-accordeon,
  .subscribe-text {
    margin-bottom: 24px;
  }

  .subscribe-text {
    width: 100%;
  }

  .secondary-footer {
    margin-top: 25px;
    padding-top: 25px;
  }

  .privacy-footer__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .payment-icon:not(:last-child) {
    margin-right: 16px;
  }
}

@media all and (max-width: 300.9px) {
  .payment-icon:not(:last-child) {
    margin-right: 7px;
  }
}

</style>
<style scoped>
.payment-icon.tell{
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

.payments-wrapper {
  margin-top: 24px;
}
</style>
